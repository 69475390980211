<template>
  <div class="flex-column" style="height: 100vh;">
    <van-nav-bar title="预约挂号" left-text="返回" @click-left="back" left-arrow />
    <div class="flex1" ref="allPage">
      <van-tabs
        type="card"
        class="tabs100"
        @change="tabChange"
        style="background:#fff;height: 100%"
        title-active-color="#fff"
        color="#20a0ff"
      >
        <van-tab title="按日期预约">
          <div class="flex-column" style="height: 100%;">
            <div ref="wrapper" style="width: 100%;overflow: hidden;">
              <ul class="cont" ref="cont" >
                <li
                  class="cont-item"
                  @click="dayClicked(item)"
                  v-for="(item, index) of days"
                  :key="index"
                  :style="checkedDay==item.monthDay?'background:#20a0ff;color:white;':''"
                >
                  <div class="cont-dest">{{item.weekDay}}</div>
                  <div class="cont-price">
                    <span class="price">{{item.monthDay}}</span>
                  </div>
                </li>
              </ul>
            </div>
            <van-pull-refresh
              class="flex1"
              style="overflow: auto;"
              v-model="isLoading"
              @refresh="loadData"
              id="dayDoctorList"
            >
              <van-list
                ref="docList"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
              >
                <van-cell
                  v-for="(item, index) in list"
                  :key="index"
                  class="item_container"
                  isLink
                  :to="'doctor?id='+item.id"
                >
                  <template #title>
                    <span style="padding:5px;">
                      <van-image :src="item.avatar" width="90" height="90" />
                    </span>
                    <span style="margin-top:7px;margin-left:10px;display:contents;">
                      <p style="font-size:20px;">{{item.name}}</p>
                      <p style="font-size:16px;margin-top:4px;color:#FF9F01;">{{item.title}}</p>
                      <p class="contentStyle">擅长领域: {{item.goodAt}}</p>
                    </span>
                  </template>
                </van-cell>
              </van-list>
            </van-pull-refresh>
          </div>
        </van-tab>
        <van-tab title="按医生预约">
          <van-pull-refresh
            style="height: 100%;overflow: auto;"
            v-model="isLoading"
            @refresh="loadData"
            id="allDoctorList"
          >
            <van-list
              ref="docList2"
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              style="overflow-y:auto;"

            >
              <van-cell
                v-for="(item, index) in allDoctorList"
                :key="index"
                class="item_container"
                isLink
                :to="'doctor?id='+item.id"
              >
                <template #title>
                  <span style="padding:5px;">
                    <van-image :src="item.avatar" width="80" height="80" />
                  </span>
                  <span style="margin-top:7px;margin-left:10px;display:contents;">
                    <p style="font-size:20px;">{{item.name}}</p>
                    <p style="font-size:16px;margin-top:4px;color:#FF9F01;">{{item.title}}</p>
                    <p class="contentStyle">擅长领域: {{item.goodAt}}</p>
                  </span>
                </template>
              </van-cell>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
export default {
  components: {},
  data() {
    return {
      daysHeight: 700,
      doctorsHeight: 700,
      tabIndex: 0,
      loading: false,
      finished: true,
      allDoctorList: [],
      days: [],
      list: [],
      show: false,
      title: "标题",
      checkedDay: null,
      isLoading: false,
    };
  },
  created() {},
  methods: {
    tabChange(val) {
      this.tabIndex = val;
      console.log(val);
    },
    loadData() {
      this.$axios
        .get("/doctor/getWechatReserveDoctor", { params: {} })
        .then((res) => {
          this.isLoading = false;
          if (res.code == "ok") {
            //加载数据
            this.days = res.data.dateList;
            this.days &&
              this.days.forEach((day) => {
                day.doctorList &&
                  day.doctorList.sort(
                    (d1, d2) => (d1.seq || 0) - (d2.seq || 0)
                  );
              });
            this.list = res.data.dateList[0].doctorList;
            this.allDoctorList = res.data.allDoctorList;
            this.allDoctorList &&
              this.allDoctorList.length &&
              this.allDoctorList.sort(
                (d1, d2) => (d1.seq || 0) - (d2.seq || 0)
              );
            this.checkedDay = this.days[0].monthDay;
            this.$nextTick(() => {
              let timer = setTimeout(() => {
                // 其实我也不想写这个定时器的，这相当于又嵌套了一层$nextTick，但是不这么写会失败
                if (timer) {
                  clearTimeout(timer);
                  this.verScroll();
                }
              }, 0);
            });
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    dayClicked(item) {
      this.checkedDay = item.monthDay;
      this.list = item.doctorList;
      console.log(this.list);
    },
    onLoad() {},
    verScroll() {
      let width = this.days.length * 1.71; // 动态计算出滚动区域的大小，前面已经说过了，产生滚动的原因是滚动区域宽度大于父盒子宽度
      this.$refs.cont.style.width = width + "rem"; // 修改滚动区域的宽度
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.wrapper, {
            startX: 0, // 配置的详细信息请参考better-scroll的官方文档，这里不再赘述
            click: true,
            scrollX: true,
            scrollY: false,
            eventPassthrough: "vertical",
          });
        } else {
          this.scroll.refresh(); //如果dom结构发生改变调用该方法
        }
      });
    },
    // 返回
    back() {
      this.$back();
    },
  },

  mounted() {
    this.loadData();
    //修正滚动区的高度
    var topHeight = 147;
    var scrollHeight = document.documentElement.clientHeight - topHeight;
    this.daysHeight = scrollHeight;

    //修正医生滚动区高度
    topHeight = 83;
    scrollHeight = document.documentElement.clientHeight - topHeight;
    this.doctorsHeight = scrollHeight;
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/reservation/doctor") {
        //判断是从哪个tab进去的
        console.log("befor enter=" + to.meta.tabIndex);
        if (to.meta.tabIndex == 0) {
          document.getElementById("dayDoctorList").scrollTop =
            to.meta.scollTopPosition;
        } else {
          document.getElementById("allDoctorList").scrollTop =
            to.meta.scollTopPosition;
        }
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    console.log(to.path);
    from.meta.tabIndex = this.tabIndex;
    if (from.meta.keepAlive) {
      if (this.tabIndex == 0) {
        from.meta.scollTopPosition = document.getElementById(
          "dayDoctorList"
        ).scrollTop;
      } else {
        from.meta.scollTopPosition = document.getElementById(
          "allDoctorList"
        ).scrollTop;
      }
    }

    next();
  },
};
</script>

<style lang="less" scoped>
.item_container {
  width: 100%;
  overflow: hidden;
}
.item_container span {
  display: block;
  float: left;
}

.one_report {
  margin-bottom: 15px;
}

.form_text {
  margin: 15px 0 30px;
  padding: 0 12px;
}

.form_btn {
  text-align: right;
}

.form_btn button {
  margin-left: 10px;
}

.one_report_data {
  margin-bottom: 20px;
  padding: 0 22px;
  div {
    line-height: 30px;
  }
}
.item_container {
  width: 100%;
  overflow: hidden;
}
.item_container span {
  display: block;
  float: left;
}
</style>
<style lang="scss" scoped>
.title {
  position: relative;
  height: 40px;
  display: flex;
  padding: 12px 0;
  box-sizing: border-box;
  .title-img {
    width: 15px;
    height: 15px;
  }
  .title-hotrec {
    font-size: 16px;
    margin-left: 4px;
  }
  .title-allrec {
    position: absolute;
    padding-top: 2px;
    font-size: 13px;
    right: 20px;
    color: gray;
  }
}
.cont {
  list-style: none;
  // overflow-x: scroll;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
  padding: 10px 0.24rem 5px 0.24rem;
  .cont-item {
    position: relative;
    display: inline-block;
    padding: 0.06rem 0 0.2rem;
    width: 1.5rem;
    margin: 0 0.1rem;
    .cont-dest {
      margin: 0.1rem 0;
      font-size: 0.4rem;
    }
  }
}

.contentStyle {
  -webkit-box-orient: vertical;
  height: 44px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  margin-top: 4px;
}
</style>

<style lang="less">
.van-nav-bar .van-icon,
.van-nav-bar__text {
  color: #20a0ff;
}
</style>
